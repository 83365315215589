import { Box, Button, ButtonGroup, Card, CardContent, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, Switch, TextField, Toolbar, Typography, Tooltip } from '@mui/material'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import AptaTable from 'examples/AptaTable'
import MDBox from 'components/MDBox'
import axios from 'axios'
import { ADMIN_SERVER } from 'constants/constant'
import MDAvatar from 'components/MDAvatar'
import { PHOTO_SERVER } from 'constants/constant'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { SERVER } from 'constants/constant'



export const Author = ({ image, name, email }) => (

    <MDBox lineHeight={1} style={{ display: "flex" }}>
        {image ? <MDAvatar src={`${PHOTO_SERVER}${image}`} style={{ height: 40, width: 40 }} name={name} /> :
            <Icon color="inherit" fontSize="large" >account_box</Icon>
        }
        <MDBox lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
                {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
    </MDBox>
);
function Feedbacks() {
    const navigation = useNavigate()
    const [feedbacks, setFeedbacks] = useState([])
    useEffect(() => {
        fetchitems()
    }, [])


    async function fetchitems() {
        try {
            const response = await axios.get(`${SERVER}/feedback/get`);
            if (response.status === 200) {
                setFeedbacks(response?.data?.reverse()); // Return the list of items
            } else {
                console.error('Unexpected response code:', response.status);
            }
        } catch (error) {
            console.log(error.response.data);

        }
    }



    return (
        <DashboardLayout>
            <DashboardNavbar />

            {/* Body */
                <AptaTable
                    columns={['name', 'email', 'message']}
                    rows={
                        feedbacks?.map((item) => ({
                            name: (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item?.name || "N/A"}
                                </MDTypography>
                            ),
                            email: (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item?.email || "N/A"}
                                </MDTypography>
                            ),

                            message: (
                                <Tooltip title={item?.message || "N/A"} arrow>
                                    <MDTypography variant="caption" color="text" fontWeight="medium">
                                        {item?.message?.length > 20
                                            ? `${item.message.substring(0, 20)}...`
                                            : item.message || "N/A"}
                                    </MDTypography>
                                </Tooltip>
                            ),

                        }))
                    }
                />

            }
        </DashboardLayout>
    )
}

export default Feedbacks


