export const sample_data = [
    {
        image: "path_to_image1",
        name: "John Michael",
        email: "john@creative-tim.com",
        title: "Manager",
        description: "Organization",
        status: "online",
        employed: "23/04/18",
    },
    {
        image: "path_to_image2",
        name: "Alexa Liras",
        email: "alexa@creative-tim.com",
        title: "Programator",
        description: "Developer",
        status: "offline",
        employed: "11/01/19",
    },
    {
        image: "path_to_image3",
        name: "Laurent Perrier",
        email: "laurent@creative-tim.com",
        title: "Executive",
        description: "Projects",
        status: "online",
        employed: "19/09/17",
    },
    {
        image: "path_to_image4",
        name: "Michael Levi",
        email: "michael@creative-tim.com",
        title: "Programator",
        description: "Developer",
        status: "online",
        employed: "24/12/08",
    },
    {
        image: "path_to_image5",
        name: "Richard Gran",
        email: "richard@creative-tim.com",
        title: "Manager",
        description: "Executive",
        status: "offline",
        employed: "04/10/21",
    },
    {
        image: "path_to_image6",
        name: "Miriam Eric",
        email: "miriam@creative-tim.com",
        title: "Programator",
        description: "Developer",
        status: "offline",
        employed: "14/09/20",
    },
];

export const roles_array = [
    {
        role: "Manager",
        permissions: {
            roles: {
                list: true,
                create: true,
                store: true,
                edit: true,
                update: true,
                delete: true,
            },
            admins: {
                list: true,
                create: true,
                store: true,
                edit: true,
                update: true,
                delete: true,
            },
            users: {
                list: false,
                edit: false,
                view: false,
                delete: false,
            },
            approvedUsers: {
                list: true,
            },
            pendingUsers: {
                list: true,
            },
        },
    },
    {
        role: "Supervisor",
        permissions: {
            roles: {
                list: true,
                create: true,
                store: true,
                edit: true,
                update: true,
                delete: true,
            },
            admins: {
                list: true,
                create: true,
                store: true,
                edit: true,
                update: true,
                delete: true,
            },
            users: {
                list: false,
                edit: false,
                view: false,
                delete: false,
            },
            approvedUsers: {
                list: true,
            },
        },
    },
    {
        role: "Staff",
        permissions: {
            roles: {
                list: true,
                create: true,
                store: true,
                edit: true,
                update: true,
                delete: true,
            },
            admins: {
                list: true,
                create: true,
                store: true,
                edit: true,
                update: true,
                delete: true,
            },
            users: {
                list: false,
                edit: false,
                view: false,
                delete: false,
            },
            approvedUsers: {
                list: true,
            },
        },
    },
];
