import axios from 'axios';
import { ADMIN_SERVER } from 'constants/constant';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import {
    Box,
    Card,
    CardContent,
    Avatar,
    Typography,
    Grid,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Icon,
    AppBar,
    Toolbar,
    IconButton,
    Tooltip
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { PHOTO_SERVER } from 'constants/constant';
import MDTypography from 'components/MDTypography';
import AptaTable from 'examples/AptaTable';
import { Author } from 'layouts/users';
import { ArrowBack } from '@mui/icons-material';
import { COLORS } from 'constants/constant';
import MDBox from 'components/MDBox';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import { SERVER } from 'constants/constant';
import moment from 'moment';

const Dashboard = () => {
    const navigate = useNavigate(); // Hook to navigate back
    const { userId } = useParams(); // Extract userId from the route
    const [user, setUser] = useState({})
    const [otherValues, setOtherValues] = useState({
        totalVehicles: "", totalRidesCreated: "", totalRidesBooked: "", totalRideRequests: ""
    })

    const fetchUser = async () => {
        try {
            const response = await axios.get(`${SERVER}/me?userId=${userId}`);
            console.log(response.data); // Inspect the API response structure
            const { user, totalVehicles, totalRidesCreated, totalRidesBooked, totalRideRequests } = response.data?.data || {};
            setUser(user || {});
            setOtherValues({ totalVehicles, totalRidesCreated, totalRidesBooked, totalRideRequests });
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };



    useEffect(() => {
        fetchUser()
    }, [])

    return (
        <DashboardLayout>
            <AppBar position="sticky" >
                <Toolbar style={{ backgroundColor: COLORS.primary, borderRadius: 10, marginBottom: 20 }}>
                    <IconButton edge="start" color="white" onClick={() => navigate(-1)} aria-label="back">
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1, color: "white" }}>
                        User Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ backgroundColor: '#f9f9f9', minHeight: '100vh' }}>


                {/* User Profile Section */}
                <Card sx={{ marginBottom: 4, borderRadius: 3 }}>
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <Avatar
                                src={user?.profilePic ? `${PHOTO_SERVER}${user?.profilePic}` : <Icon color="inherit" fontSize="large" >account_box</Icon>}
                                alt={user?.name}
                                sx={{
                                    width: 100,
                                    height: 100,
                                    marginRight: 3,
                                }}
                            />
                            <Box>
                                <Typography variant="h5" fontWeight="bold">
                                    {user?.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {user?.email}
                                </Typography>
                                {/* <Typography variant="body2" color="text.secondary">
                                    Role: {user?.role}
                                </Typography> */}
                                <Typography variant="body2" color={!user?.flag ? 'green' : 'red'}>
                                    {user?.flag ? 'Flagged' : 'Active'}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ marginY: 3 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                    <strong>Phone:</strong> {user?.phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                    <strong>Driving License Verified:</strong>{' '}
                                    {user?.drivingLicense?.verified ? (
                                        <CheckCircleIcon color="success" />
                                    ) : (
                                        <HighlightOffIcon color="error" />
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                    <strong>Account Created:</strong> {new Date(user?.createdAt)?.toLocaleDateString()}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                    <strong>Aadhaar Verified:</strong>{' '}
                                    {user?.aadhaar?.verified ? (
                                        <CheckCircleIcon color="success" />
                                    ) : (
                                        <HighlightOffIcon color="error" />
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                {/* Statistics Section */}

                <MDBox mx={5} py={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="directions_car"
                                    title="Vehicles"
                                    count={otherValues?.totalVehicles || 0}
                                    percentage={{
                                        color: "success",
                                        amount: "+55%",
                                        label: "than last week",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="directions_car"
                                    title="Published"
                                    count={otherValues?.totalRidesCreated || 0}
                                    percentage={{
                                        color: "success",
                                        amount: "+3%",
                                        label: "than last month",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="info"
                                    icon="feedback"
                                    title="Booked"
                                    count={otherValues?.totalRidesBooked || 0}
                                    percentage={{
                                        color: "success",
                                        amount: "+1%",
                                        label: "than yesterday",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="warning"
                                    icon="forum"
                                    title="Requests"
                                    count={otherValues?.totalRideRequests || 0}
                                    percentage={{
                                        color: "success",
                                        amount: "",
                                        label: "Just updated",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={12}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="warning"
                                    icon="wallet"
                                    title="Wallet"
                                    count={"₹ " + `${String(Number(user?.wallet?.balance).toFixed(2))}` || 0}
                                    percentage={{
                                        color: "success",
                                        amount: "",
                                        label: "Just updated",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>

                {/* Subscription */}
                {/* Subscription Details */}
                {user?.subscription && (
                    <Card sx={{ marginBottom: 4, borderRadius: 3 }}>
                        <CardContent>
                            <Typography variant="h6" fontWeight="bold" marginBottom={2}>
                                Subscription Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                        <strong>Plan:</strong> {user?.subscription?.plan || "N/A"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                        <strong>Status:</strong> {user?.subscription?.isActive ? "Active" : "Inactive"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                        <strong>Start Date:</strong>{' '}
                                        {moment(user?.subscription?.startDate).format('MMMM Do YYYY, h:mm A')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                        <strong>End Date:</strong>{' '}
                                        {moment(user?.subscription?.endDate).format('MMMM Do YYYY, h:mm A')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}

                {/* Vehicle List Section */}
                <Card sx={{ borderRadius: 3 }}>
                    <CardContent>
                        <Typography variant="h6" fontWeight="bold" marginBottom={2}>
                            Vehicles
                        </Typography>
                        <AptaTable
                            columns={['Name', 'Type', 'Registration Number', 'Offering', 'Created At']}
                            rows={
                                (user?.vehicles || [])?.map((vehicle) => ({
                                    Name: (
                                        <Author image={vehicle?.photo} name={vehicle?.vehicleName} email={vehicle?.color} />
                                    ),
                                    Type: (
                                        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                            {vehicle?.vehicleType}
                                        </MDTypography>
                                    ),
                                    "Registration Number": (
                                        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                            {vehicle?.registrationNumber}
                                        </MDTypography>
                                    ),
                                    Offering: (
                                        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                            {vehicle?.seatsOffering}
                                        </MDTypography>
                                    ),
                                    "Created At": (
                                        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                            {new Date(vehicle?.createdAt)?.toLocaleDateString()}
                                        </MDTypography>
                                    ),
                                })) || [] // Fallback to empty array if undefined
                            }

                        />

                    </CardContent>
                </Card>

                {/* reviews */}
                <Card sx={{ borderRadius: 3 }}>
                    <CardContent>
                        <Typography variant="h6" fontWeight="bold" marginBottom={2}>
                            Reviews
                        </Typography>
                        <AptaTable
                            columns={['Reviewer', 'Rating', 'Comment', 'Created At']}
                            rows={
                                (user?.reviewsReceived || [])?.map((review) => ({
                                    Reviewer: (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {review?.reviewer?.name || "N/A"}
                                        </MDTypography>
                                    ),
                                    Rating: (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {review?.rating || "N/A"}
                                        </MDTypography>
                                    ),
                                    Comment: (
                                        <Tooltip title={review?.comment || "N/A"} arrow>
                                            <MDTypography variant="caption" color="text" fontWeight="medium">
                                                {review?.comment?.length > 20
                                                    ? `${review.comment.substring(0, 20)}...`
                                                    : review.comment || "N/A"}
                                            </MDTypography>
                                        </Tooltip>
                                    ),
                                    "Created At": (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {moment(review?.createdAt).format('MMMM Do YYYY, h:mm A') || "N/A"}
                                        </MDTypography>
                                    ),
                                })) || [] // Fallback to empty array if undefined
                            }

                        />
                    </CardContent>
                </Card>
                {/* transactions */}
                <Card sx={{ borderRadius: 3 }}>
                    <CardContent>
                        <Typography variant="h6" fontWeight="bold" marginBottom={2}>
                            Transactions
                        </Typography>
                        <AptaTable
                            columns={['paymentId', 'type', 'amount', 'status', "date"]}
                            rows={
                                (user?.wallet?.transactions || [])?.map((review) => ({
                                    paymentId: (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {review?.paymentId || "N/A"}
                                        </MDTypography>
                                    ),
                                    type: (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {review?.type || "N/A"}
                                        </MDTypography>
                                    ),
                                    amount: (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {review?.amount || "N/A"}
                                        </MDTypography>
                                    ),
                                    "status": (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {review?.status || "N/A"}
                                        </MDTypography>
                                    ),
                                    "date": (
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {moment(review?.date).format('MMMM Do YYYY, h:mm A') || "N/A"}
                                        </MDTypography>
                                    ),
                                })) || [] // Fallback to empty array if undefined
                            }

                        />
                    </CardContent>
                </Card>
            </Box>
        </DashboardLayout>

    );
};

export default Dashboard;

