import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Paper, Box, CardMedia, CardContent, CardActions, CardActionArea, Card, Icon, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, styled } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import ReusableEditor from './editor';
import { SERVER } from 'constants/constant';
import axios from 'axios';
import { ADMIN_SERVER } from 'constants/constant';
import Swal from 'sweetalert2';

function Website() {
    // State for dynamic content
    const [testimonials, setTestimonials] = useState([
        { name: "John Doe", comment: "Amazing service!" },
        { name: "Jane Smith", comment: "Very professional and fast!" }
    ]);
    const [openTesti, setOpenTesti] = useState(false)
    const [testiData, setTestiData] = useState({
        name: '',
        desc: "",
        photo: null
    })
    const [contactInfo, setContactInfo] = useState({
        "phone": "+1234567890",
        "email": "support@newdomain.com",
        "whatsapp": "+1234567890",
        "description": "Updated support details."
    });

    const [policies, setPolicies] = useState({
        privacyPolicy: "Privacy policy content goes here.",
        returnPolicy: "Return policy content goes here.",
        termsConditions: "Terms and conditions content goes here.",
        referToFriend: "Refer to friend content goes here."
    });

    // Function to update contact info
    const updateContactInfo = async () => {
        await axios.put(`${ADMIN_SERVER}/customer-support/edit`, contactInfo).then(res => {
            if (res.data) {
                Swal.fire({
                    title: "Success!",
                    text: "contact info successfully updated!",
                    icon: "success"
                });
            }
        })
    };

    const getContactInfo = async () => {
        await axios.get(`${ADMIN_SERVER}/customer-support/get`).then(res => {
            if (res.data) {
                setContactInfo(res.data)
            }
        })
    }


    useEffect(() => {
        getContactInfo()
    }, [])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="lg" sx={{ paddingY: 4, }}>
                {/* Example Form to Update Content */}
                <Paper sx={{ padding: 3, marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>Contact</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                value={contactInfo.phone}
                                onChange={(e) => setContactInfo({ ...contactInfo, phone: e.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                value={contactInfo.email}
                                onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Whatsapp"
                                variant="outlined"
                                fullWidth
                                value={contactInfo.whatsapp}
                                onChange={(e) => setContactInfo({ ...contactInfo, whatsapp: e.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                value={contactInfo.description}
                                onChange={(e) => setContactInfo({ ...contactInfo, description: e.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                            <Button style={{ color: 'white' }}
                                variant="contained"
                                color="primary"
                                onClick={updateContactInfo}
                            // fullWidth
                            >
                                Update Contact Info
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                {/* Testimonials Section */}
                {/* <Paper sx={{ padding: 3, marginBottom: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                        <Typography variant="h4" gutterBottom>Testimonials</Typography>
                        <Button style={{ color: 'white' }} startIcon={<Icon>add</Icon>}
                            onClick={() =>
                                // handleOpen('add', {
                                //     name: "",
                                //     email: "",
                                //     mobile: "",
                                //     password: "",
                                //     role: "",

                                // })
                                setOpenTesti(true)
                            }
                            variant='contained'  >Add</Button>                    </Box>

                    <Container sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', height: 500, overflowY: 'auto' }}>
                        {[1, 1, 1, 1, 1, 1, 1, 1]?.map((testimonial, index) => (

                            <TestimonialCard />
                        ))}
                    </Container>
                </Paper> */}


                <div>
                    {/* Terms and Conditions */}
                    <ReusableEditor
                        title="Terms and Conditions"
                        getApi={`${SERVER}/api/terms`}
                        postApi={`${SERVER}/api/terms`}
                    />

                    {/* Privacy Policy */}
                    <ReusableEditor
                        title="Privacy Policy"
                        getApi={`${SERVER}/api/privacy`}
                        postApi={`${SERVER}/api/privacy`}
                    />

                    {/* Return Policy */}
                    <ReusableEditor
                        title="Return Policy"
                        getApi={`${SERVER}/api/return`}
                        postApi={`${SERVER}/api/return`}
                    />
                </div>

                {/* Add Testimonial */}
                <ProfileModal open={openTesti} handleClose={() => setOpenTesti(false)} details={testiData} />
            </Container>
        </DashboardLayout>
    );
}

export default Website;


function TestimonialCard() {
    return (
        <Card sx={{ width: { xs: 250, ms: 400 } }}>
            <ButtonGroup size='small' style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Button color='info'
                // onClick={() => handleOpen('edit', item)} 
                >
                    <Icon color='info' >edit</Icon>
                </Button>
                <Button color="error">
                    <Icon color='error'>delete</Icon>
                </Button>
            </ButtonGroup>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image="/profile.png"
                    alt="green iguana"
                    style={{ display: 'flex', placeSelf: 'center' }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
                        Alice Johnson
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 14 }}>
                        The best service I've ever had! The team is always there to assist and make sure everything goes smoothly. Will come back for sure.
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}


function ProfileModal({ open, handleClose, details }) {
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [image, setImage] = useState(null); // Store the actual file

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file); // Save the file itself
        }
    };
    useEffect(() => {
        if (details) {
            setName(details?.name)
            setDesc(details?.desc)
            setImage(details?.photo)
        }
    }, [])

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("desc", desc);
        formData.append("image", image); // Append the file directly

        // Example: Sending formData to a 
        handleClose(); // Close the modal after submission
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Enter Profile Details</DialogTitle>
            <DialogContent>
                <Container sx={{ justifyContent: 'center', display: 'flex', }}>
                    {image ? (
                        <label htmlFor="profile-image-upload">
                            <img
                                src={URL.createObjectURL(image)}
                                alt="Profile"
                                style={{ maxWidth: "100px", cursor: 'pointer', borderRadius: 50 }}
                            />
                        </label>
                    ) : <label htmlFor="profile-image-upload" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, height: 100, border: '2px solid black', borderRadius: 50 }}>
                        <IconButton color="primary" component="span">
                            <Icon color='info'>person</Icon>
                        </IconButton>
                    </label>}
                </Container>
                <TextField
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Description"
                    fullWidth
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                />
                <div style={{ marginBottom: "20px" }}>
                    <input
                        accept="image/*"
                        id="profile-image-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                    />


                </div>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions> */}
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} style={{ color: "white" }} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}




const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});