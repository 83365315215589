import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Icon,
} from "@mui/material";
import Swal from "sweetalert2";
import AptaTable from "examples/AptaTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { ADMIN_SERVER } from "constants/constant";

function BlogCategory() {
    const [categories, setCategories] = useState([]);
    const [formDetails, setFormDetails] = useState({
        blogContent: "",
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState("add"); // 'add' or 'edit'

    // Fetch all blog categories
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${ADMIN_SERVER}/get-blog-categories`);
            setCategories(response.data);
        } catch (error) {
            Swal.fire("Error", "Failed to fetch categories.", "error");
            console.error("Error fetching categories:", error);
        }
    };

    // Handle modal open
    const handleOpenModal = (type, category = { blogContent: "" }) => {
        setModalType(type);
        setFormDetails(category);
        setModalOpen(true);
    };

    // Handle modal close
    const handleCloseModal = () => setModalOpen(false);

    // Handle save action
    const handleSave = async (formDetails) => {
        const url =
            modalType === "add"
                ? `${ADMIN_SERVER}/create-blog-category`
                : `${ADMIN_SERVER}/update-blog-category/${formDetails._id}`;
        const method = modalType === "add" ? "POST" : "PUT";

        try {
            await axios({
                method,
                url,
                data: { blogContent: formDetails.blogContent },
            });
            Swal.fire("Success", `Category ${modalType === "add" ? "created" : "updated"} successfully!`, "success");
            fetchCategories();
            handleCloseModal();
        } catch (error) {
            Swal.fire("Error", "Failed to save category.", "error");
            console.error("Error saving category:", error);
        }
    };

    // Handle delete action
    const handleDelete = async (id) => {
        try {
            await axios.delete(`${ADMIN_SERVER}/delete-blog-category/${id}`);
            Swal.fire("Success", "Category deleted successfully!", "success");
            fetchCategories();
        } catch (error) {
            Swal.fire("Error", "Failed to delete category.", "error");
            console.error("Error deleting category:", error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box display="flex" mb={3} justifyContent="end">
                <Button
                    style={{ color: "white" }}
                    startIcon={<Icon>add</Icon>}
                    onClick={() =>
                        handleOpenModal("add", {
                            blogContent: "",
                        })
                    }
                    variant="contained"
                >
                    Add Blog Category
                </Button>
            </Box>
            <AptaTable
                columns={["Blog Content", "Actions"]}
                rows={categories?.map((category) => ({
                    "Blog Content": (
                        <MDTypography variant="button" fontWeight="medium">
                            {category?.blogContent}
                        </MDTypography>
                    ),
                    Actions: (
                        <ButtonGroup size="small">
                            <Button color="info" onClick={() => handleOpenModal("edit", category)}>
                                <Icon color="info">edit</Icon>
                            </Button>
                            <Button color="error" onClick={() => handleDelete(category?._id)}>
                                <Icon color="error">delete</Icon>
                            </Button>
                        </ButtonGroup>
                    ),
                }))}
            />
            <BlogCategoryModal
                open={modalOpen}
                onClose={handleCloseModal}
                onSave={handleSave}
                details={formDetails}
                type={modalType}
            />
        </DashboardLayout>
    );
}

export default BlogCategory;

const BlogCategoryModal = ({ open, onClose, onSave, details, type }) => {
    const [formData, setFormData] = useState({ blogContent: "" });

    useEffect(() => {
        if (details) {
            setFormData(details);
        }
    }, [details]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        onSave(formData);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{type === "add" ? "Add Blog Category" : "Edit Blog Category"}</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                    <TextField
                        label="Blog Content"
                        name="blogContent"
                        value={formData.blogContent}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} style={{ color: "white" }} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
