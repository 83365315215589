import { Box, Button, ButtonGroup, Card, CardContent, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, Switch, TextField, Typography } from '@mui/material'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { sample_data } from './data/constant'
import AptaTable from 'examples/AptaTable'
import MDBox from 'components/MDBox'
import { SERVER } from 'constants/constant'
import axios from 'axios'
import { ADMIN_SERVER } from 'constants/constant'
import MDAvatar from 'components/MDAvatar'
import { PHOTO_SERVER } from 'constants/constant'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Search } from '@mui/icons-material'




export const Author = ({ image, name, email }) => (

    <MDBox lineHeight={1} style={{ display: "flex" }}>
        {image ? <MDAvatar src={`${PHOTO_SERVER}${image}`} style={{ height: 40, width: 50, }} name={name} /> :
            <div style={{ height: 40, width: 50, }}>
                <Icon color="inherit" fontSize="large" >account_circle</Icon>
            </div>
        }
        <MDBox lineHeight={1} >
            <MDTypography display="block" variant="button" fontWeight="medium">
                {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
    </MDBox>
);
function Users() {
    const navigation = useNavigate()
    const [users, setUsers] = useState([])
    const [currentItem, setCurrentItem] = useState({})
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchUsers()
    }, [])



    async function fetchUsers() {
        try {
            const response = await axios.get(`${ADMIN_SERVER}/users`);
            if (response.status === 200) {
                console.log('Users retrieved successfully:', response.data.data.users);
                setUsers(response.data.data.users); // Return the list of users
            } else {
                console.error('Unexpected response code:', response.status);
            }
        } catch (error) {
            if (error.response) {
                // Server responded with a status code outside the range of 2xx
                console.error('Error response from server:', error.response.data.error || 'Unknown error');
            } else if (error.request) {
                // Request was made but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something happened while setting up the request
                console.error('Error setting up request:', error.message);
            }
        }
    }

    async function updateUser(updateData) {
        const url = `${ADMIN_SERVER}/users/update/${updateData?.id}`;

        try {
            const response = await axios.put(url, { ...updateData, userId: updateData?.id }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                Swal.fire({
                    title: updateData?.flag ? "Flagged!" : "Unflagged",
                    text: updateData?.flag ? "The user had been flagged." : "The user has been unflagged",
                    icon: "success"
                });
                fetchUsers()
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to flag User',
                text: 'An unexpected error occurred. Please try again !',
                confirmButtonText: 'OK',
            });
            if (error.response) {
                console.error('Error:', error.response.data);
                return error.response.data;
            } else {
                console.error('Error:', error.message);
                throw new Error('Unexpected error occurred.');
            }
        }
    }
    // Search
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUsers = users?.filter((user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.phone.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SearchInput searchQuery={searchQuery} handleSearchChange={handleSearchChange} />

            {/* Body */}
            <AptaTable
                columns={['name', 'mobile', 'flag', 'view']}
                rows={
                    filteredUsers?.map((item, index) => ({
                        name: <Author image={item?.profilePic} name={item?.name} email={item?.email} />,
                        mobile:
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {item?.phone}
                            </MDTypography>
                        ,
                        category:
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {item?.category}
                            </MDTypography>
                        ,
                        flag: <Switch
                            checked={item?.flag}
                            onChange={(e) => {
                                // setUpdateData({
                                //     flag: e.target.checked,
                                //     active: true,
                                //     role: "user",
                                //     id: item?._id
                                // })
                                Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        updateUser({
                                            flag: !e.target.checked,
                                            active: true,
                                            role: "user",
                                            id: item?._id
                                        })
                                    }
                                });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            color='warning'
                        />,
                        view: (
                            <Button variant="contained" onClick={() => {
                                // setCurrentItem(item)
                                // setOpen(true)
                                navigation(`/user-dashboard/${item?._id}`)
                            }} style={{ color: "white" }}>
                                <Icon>visibility</Icon>
                            </Button>
                        ),

                    }))
                }


            />
            {/* <InputFormModal open={open} onClose={handleClose} onSave={handleSave} details={details} type={roleType} /> */}
            <ViewModal open={open} handleClose={() => setOpen(false)} user={currentItem} />
        </DashboardLayout>
    )
}

export default Users

const ViewModal = ({ open, handleClose, user }) => {
    if (!user) return null;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    {/* User Info */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            fontSize: "0.9rem", // Slightly smaller font size
                        }}
                    >
                        <Typography>
                            <strong>Name:</strong> {user.name}
                        </Typography>
                        <Typography>
                            <strong>Email:</strong> {user.email}
                        </Typography>
                        <Typography>
                            <strong>Phone:</strong> {user.phone}
                        </Typography>
                        <Typography>
                            <strong>Verified:</strong> {user.isVerified ? "Yes" : "No"}
                        </Typography>
                        <Typography>
                            <strong>Joined On:</strong> {new Date(user.createdAt).toLocaleDateString()}
                        </Typography>
                    </Box>

                    {/* Vehicle List */}
                    <Typography variant="h6" mt={3}>
                        Vehicles:
                    </Typography>
                    <Box
                        sx={{
                            maxHeight: 300,
                            overflowY: "auto",
                            padding: 1,
                            border: "1px solid #ddd",
                            borderRadius: 2,
                            bgcolor: "#f9f9f9", // Light background for contrast
                        }}
                    >
                        <Grid container spacing={2}>
                            {user?.vehicles?.map((vehicle) => (
                                <Grid item xs={12} sm={6} md={4} key={vehicle._id}>
                                    <Card>
                                        {vehicle?.photo && vehicle?.photo?.[0] && (
                                            <CardMedia
                                                component="img"
                                                height="75"
                                                image={`${PHOTO_SERVER}${vehicle?.photo?.[0]}`} // Adjust the path based on your API or storage setup
                                                alt={vehicle?.vehicleName}
                                            />
                                        )}
                                        <CardContent>
                                            <Typography variant="subtitle1">
                                                <strong>{vehicle?.vehicleName}</strong>
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Type:</strong> {vehicle?.vehicleType}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Seats:</strong> {vehicle?.seatsOffering}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Color:</strong> {vehicle?.color}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>

            </DialogActions>
        </Dialog>
    );
};







export const SearchInput = ({ searchQuery, handleSearchChange, placeholder }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            mb={3}
            sx={{
                // maxWidth: 600,
                width: '100%',
                backgroundColor: '#f5f5f5',
                borderRadius: '50px',
                // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                // padding: '8px 16px',
            }}
        >
            <TextField
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={placeholder ? placeholder : "Search for name, mobile..."}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '50px',
                        padding: '8px 12px',
                    },
                    '& .MuiInputBase-input': {
                        fontSize: '16px',
                        padding: '8px',
                    },
                }}
            />
        </Box>
    );
};

