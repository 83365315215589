import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Icon, Grid, Paper } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ADMIN_SERVER } from 'constants/constant';

const Charges = () => {
    const [editMode, setEditMode] = useState(false);
    const [charges, setCharges] = useState({
        percentage: "0",
        signupBonus: "0",
        newUserBonus: "0",
        referrerBonus: "0",
        priceSuggestion: "0",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCharges({ ...charges, [name]: value });
    };

    const getCharges = async () => {
        try {
            const res = await axios.get(`${ADMIN_SERVER}/get-percentage`);
            const res2 = await axios.get(`${ADMIN_SERVER}/referralBonus/get`);
            const res3 = await axios.get(`${ADMIN_SERVER}/signupBonus/get`);
            const res4 = await axios.get(`${ADMIN_SERVER}/admin-price/get`);

            setCharges({
                ...charges,
                ...res.data,
                newUserBonus: res2?.data?.data?.newUserBonus,
                referrerBonus: res2?.data?.data?.referrerBonus,
                signupBonus: res3?.data?.data?.signupBonus,
                priceSuggestion: res4?.data?.priceDetails,
            });
        } catch (err) {
            Swal.fire('Error', err.message, 'error');
        }
    };

    const handleSave = async (field) => {
        try {
            let url = "";
            let body = {};

            if (field === "signupBonus") {
                url = `${ADMIN_SERVER}/signupBonus/update`;
                body = { signupBonus: charges.signupBonus };
            } else if (field === "newUserBonus" || field === "referrerBonus") {
                url = `${ADMIN_SERVER}/referralBonus/update`;
                body = {
                    newUserBonus: charges.newUserBonus,
                    referrerBonus: charges.referrerBonus,
                };
            } else if (field === "priceSuggestion") {
                url = `${ADMIN_SERVER}/admin-price/edit`;
                body = { priceDetails: charges.priceSuggestion };
            } else {
                url = `${ADMIN_SERVER}/edit-percentage`;
                body = { percentage: charges.percentage };
            }

            const response = await axios.put(url, body, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.status === 200) {
                Swal.fire('Success', `${field.replace(/([A-Z])/g, ' $1')} updated successfully!`, 'success');
            }

            getCharges();
            setEditMode(false);
        } catch (error) {
            Swal.fire('Error', error.response?.data?.message || error.message, 'error');
        }
    };

    useEffect(() => {
        getCharges();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box display={'flex'} justifyContent={'end'} mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditMode(!editMode)}
                    style={{ color: 'white' }}
                >
                    <Icon>{editMode ? 'close' : 'edit'}</Icon>
                </Button>
            </Box>
            <Paper
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={2}
                sx={{ margin: 'auto', padding: 5 }}
            >
                <Grid container spacing={2}>
                    {/* Commission (%) */}
                    <Grid item xs={12} md={12} display="flex" alignItems="center">
                        <TextField
                            label="Commission (%)"
                            variant="outlined"
                            name="percentage"
                            value={charges.percentage}
                            onChange={handleChange}
                            disabled={!editMode}
                            fullWidth
                        />
                        {editMode && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSave('percentage')}
                                style={{ marginLeft: 16, color: 'white' }}
                            >
                                Save
                            </Button>
                        )}
                    </Grid>

                    {/* Signup Bonus */}
                    <Grid item xs={12} md={12} display="flex" alignItems="center">
                        <TextField
                            label="Sign Up Bonus"
                            variant="outlined"
                            name="signupBonus"
                            value={charges.signupBonus}
                            onChange={handleChange}
                            disabled={!editMode}
                            fullWidth
                        />
                        {editMode && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSave('signupBonus')}
                                style={{ marginLeft: 16, color: 'white' }}
                            >
                                Save
                            </Button>
                        )}
                    </Grid>

                    {/* New User and Referrer Bonus */}
                    <Grid item xs={12} md={6} display="flex" alignItems="center">
                        <TextField
                            label="New User Bonus"
                            variant="outlined"
                            name="newUserBonus"
                            value={charges.newUserBonus}
                            onChange={handleChange}
                            disabled={!editMode}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" alignItems="center">
                        <TextField
                            label="Referrer Bonus"
                            variant="outlined"
                            name="referrerBonus"
                            value={charges.referrerBonus}
                            onChange={handleChange}
                            disabled={!editMode}
                            fullWidth
                        />
                        {editMode && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSave('newUserBonus')}
                                style={{ marginLeft: 16, color: 'white' }}
                            >
                                Save
                            </Button>
                        )}
                    </Grid>

                    {/* Price Suggestion */}
                    <Grid item xs={12} md={12} display="flex" alignItems="center">
                        <TextField
                            label="Price Suggestion"
                            variant="outlined"
                            name="priceSuggestion"
                            value={charges.priceSuggestion}
                            onChange={handleChange}
                            disabled={!editMode}
                            fullWidth
                        />
                        {editMode && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSave('priceSuggestion')}
                                style={{ marginLeft: 16, color: 'white' }}
                            >
                                Save
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </DashboardLayout>
    );
};

export default Charges;
