import React from 'react';
import DataTable from 'examples/Tables/DataTable';

function AptaTable({ columns, columnsModify = false, rows }) {
    // Prepare columns based on the `columnsModify` flag
    const preparedColumns = columnsModify
        ? columns
        : columns?.map(item => ({ Header: item, accessor: item }));

    return (
        <div>
            <DataTable
                table={{
                    columns: preparedColumns,
                    rows,
                }}
            // entriesPerPage={true} // Enable pagination
            // showTotalEntries={true} // Show total number of rows
            // isSorted={true} // Enable sorting functionality
            // defaultPageSize={1} // Set a default number of rows per page
            // noEndBorder={true} // Remove end border for a clean UI
            />
        </div>
    );
}

export default AptaTable;
