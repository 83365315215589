import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Divider, Box, Avatar, IconButton, AppBar, Toolbar } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import { PHOTO_SERVER } from 'constants/constant';
import AptaTable from 'examples/AptaTable';
import moment from 'moment';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { COLORS } from 'constants/constant';
import axios from 'axios';
import { SERVER } from 'constants/constant';
import Swal from 'sweetalert2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'

function RideDashboard() {
    // const { ride } = useParams(); // Data fetched dynamically from URL params
    const navigate = useNavigate();
    const { rideId } = useParams();
    const [ride, setRide] = useState([])

    const fetchRide = async () => {
        await axios.get(`${SERVER}/rider/getRide-details/${rideId}`).then(res => {
            if (res.data) {
                setRide(res.data?.data)
            }
        }).catch(err => {
            Swal.fire({
                title: "Error!",
                text: "Failed to fetch ride details!",
                icon: "danger"
            });
        })
    }
    useEffect(() => {
        fetchRide()
    }, [])

    // Now you can access the 'ride' object in this component
    //     console.log(ride, 'sdsdd');


    return (
        <DashboardLayout>
            {/* Header with Back Button */}
            <AppBar position="sticky">
                <Toolbar style={{ backgroundColor: COLORS.primary, marginBottom: 10, borderRadius: 10 }}>
                    <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1, color: "white" }}>
                        Ride Details
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Main Content */}
            <Grid container spacing={2} padding={2}>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Rider Info</Typography>
                            <Divider />
                            <Box display="flex" alignItems="center" marginBottom={2}>
                                <Avatar
                                    src={ride?.user?.profilePic ? `${PHOTO_SERVER}${ride.user.profilePic}` : null}
                                    alt={ride?.user?.name || "No Name"}
                                />
                                <Box marginLeft={2}>
                                    <Typography variant="h6">{ride?.user?.name || "N/A"}</Typography>
                                    <Typography variant="body1"><strong>Email:</strong> {ride?.user?.email || "N/A"}</Typography>
                                    <Typography variant="body1"><strong>Phone:</strong> {ride?.user?.phone || "N/A"}</Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Ride Information Card */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Ride Details</Typography>
                            <Divider />
                            <Typography variant="body1"><strong>Pickup Location:</strong> {ride?.pickupLocationName || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Drop Location:</strong> {ride?.dropLocationName || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Ride Status:</strong> {ride?.rideStatus || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Available Seats:</strong> {ride?.availableSeats || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Price:</strong> ₹{ride?.price || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Description:</strong> {ride?.description || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Start Time:</strong> {ride?.startTime ? moment(ride.startTime).format('MMMM Do YYYY, h:mm A') : "N/A"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Vehicle Details */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Vehicle Details</Typography>
                            <Divider />
                            <Typography variant="body1"><strong>Vehicle Name:</strong> {ride?.vehicle?.vehicleName || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Vehicle Type:</strong> {ride?.vehicle?.vehicleType || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Registration Number:</strong> {ride?.vehicle?.registrationNumber || "N/A"}</Typography>
                            <Typography variant="body1"><strong>Seats Offering:</strong> {ride?.vehicle?.seatsOffering || "N/A"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Ride Requests Table */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Ride Requests</Typography>
                            <Divider />
                            <AptaTable
                                columns={['Name', 'Phone', 'Members', 'Status']}
                                rows={
                                    ride?.requests?.length
                                        ? ride.requests.map((request, index) => ({
                                            'Name': (
                                                <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                                    {request.user?.name || "N/A"}
                                                </MDTypography>
                                            ),
                                            'Phone': (
                                                <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                                    {request.user?.phone || "N/A"}
                                                </MDTypography>
                                            ),
                                            'Members': (
                                                <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                                    {request.numberOfMembers || "N/A"}
                                                </MDTypography>
                                            ),
                                            'Status': (
                                                <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                                                    {request.status || "N/A"}
                                                </MDTypography>
                                            ),
                                        }))
                                        : []
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default RideDashboard;
