import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { Button } from "@mui/material";

const ReusableEditor = ({ title, getApi, postApi }) => {
    const [editorContent, setEditorContent] = useState(""); // Content in the editor
    const [loading, setLoading] = useState(false);

    // Fetch data on mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(getApi);
                setEditorContent(response.data.content || "");
            } catch (error) {
                console.error("Error fetching content:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [getApi]);

    // Save content to backend
    const handleSave = async () => {
        try {
            setLoading(true);
            await axios.post(postApi, { content: editorContent });
            alert("Content saved successfully!");
        } catch (error) {
            console.error("Error saving content:", error.message);
            alert("Failed to save content.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ margin: "20px auto", padding: "10px", backgroundColor: "white" }}>
            <h2>{title}</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <ReactQuill
                        // theme="snow"
                        value={editorContent}
                        onChange={setEditorContent}
                        style={{ height: "300px", marginBottom: "20px", }}
                    />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            onClick={handleSave}
                            style={{
                                background: "#1976d2",
                                color: "#fff",
                                padding: "10px 20px",
                                marginTop: 50,
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        >
                            Save
                        </Button>
                    </div>
                    <h3>Preview</h3>
                    <div
                        style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#f9f9f9",
                        }}
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                    />
                </>
            )}
        </div>
    );
};

export default ReusableEditor;
