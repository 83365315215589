import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Grid,
    Icon,
} from "@mui/material";
import AptaTable from "examples/AptaTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { ADMIN_SERVER } from "constants/constant";

// Base API URL
function Plans() {
    const [plans, setPlans] = useState([]);
    const [formDetails, setFormDetails] = useState({
        planName: "",
        PlanPercentage: 0,
        description: "",
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState("add"); // 'add' or 'edit'

    // Fetch all plans
    const fetchPlans = async () => {
        try {
            const response = await axios.get(`${ADMIN_SERVER}/subscriptions/all-plans`);
            setPlans(response.data);
        } catch (error) {
            console.error("Error fetching plans:", error);
        }
    };

    // Handle modal open
    const handleOpenModal = (type, plan = { planName: "", PlanPercentage: 0, description: "" }) => {
        setModalType(type);
        setFormDetails(plan);
        setModalOpen(true);
    };

    // Handle modal close
    const handleCloseModal = () => setModalOpen(false);

    // Handle save action
    const handleSave = async (formDetails) => {
        const url = modalType === "add" ? `${ADMIN_SERVER}/subscriptions/create-plan` : `${ADMIN_SERVER}/subscriptions/update/${formDetails._id}`;
        const method = modalType === "add" ? "POST" : "PUT";

        try {
            await axios({
                method,
                url,
                data: formDetails,
            });
            fetchPlans();
            handleCloseModal();
        } catch (error) {
            console.error("Error saving plan:", error);
        }
    };

    // Handle delete action
    const handleDelete = async (id) => {
        try {
            await axios.delete(`${ADMIN_SERVER}/subscriptions/delete/${id}`);
            fetchPlans();
        } catch (error) {
            console.error("Error deleting plan:", error);
        }
    };

    useEffect(() => {
        fetchPlans();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box display="flex" mb={3} justifyContent="end">
                <Button
                    style={{ color: "white" }}
                    startIcon={<Icon>add</Icon>}
                    onClick={() =>
                        handleOpenModal("add", {
                            planName: "",
                            PlanPercentage: 0,
                            description: "",
                        })
                    }
                    variant="contained"
                >
                    Add Plan
                </Button>
            </Box>
            <AptaTable
                columns={["Plan Name", "Percentage", "Description", "Actions"]}
                rows={plans?.map((plan) => ({
                    "Plan Name": (
                        <MDTypography variant="button" fontWeight="medium">
                            {plan?.planName?.toUpperCase()}
                        </MDTypography>
                    ),
                    Percentage: `${plan.PlanPercentage}%`,
                    Description: plan.description,
                    Actions: (
                        <ButtonGroup size="small">
                            <Button color="info" onClick={() => handleOpenModal("edit", plan)}>
                                <Icon color="info">edit</Icon>
                            </Button>
                            <Button color="error" onClick={() => handleDelete(plan?._id)}>
                                <Icon color="error">delete</Icon>
                            </Button>
                        </ButtonGroup>
                    ),
                }))}
            />
            <PlanModal
                open={modalOpen}
                onClose={handleCloseModal}
                onSave={handleSave}
                details={formDetails}
                type={modalType}
            />
        </DashboardLayout>
    );
}

export default Plans;

const PlanModal = ({ open, onClose, onSave, details, type }) => {
    const [formData, setFormData] = useState({ planName: "", PlanPercentage: 0, description: "" });

    useEffect(() => {
        if (details) {
            setFormData(details);
        }
    }, [details]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        onSave(formData);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{type === "add" ? "Add Plan" : "Edit Plan"}</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                    <TextField
                        label="Plan Name"
                        name="planName"
                        value={formData.planName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Plan Percentage"
                        name="PlanPercentage"
                        type="number"
                        value={formData.PlanPercentage}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} style={{ color: "white" }} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
