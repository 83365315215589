import { Box, Button, ButtonGroup, Card, CardContent, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, Switch, TextField, Typography } from '@mui/material'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { sample_data } from './data/constant'
import AptaTable from 'examples/AptaTable'
import MDBox from 'components/MDBox'
import { SERVER } from 'constants/constant'
import axios from 'axios'
import { ADMIN_SERVER } from 'constants/constant'
import MDAvatar from 'components/MDAvatar'
import { PHOTO_SERVER } from 'constants/constant'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Search } from '@mui/icons-material'


export const Author = ({ image, name, email }) => (

    <MDBox lineHeight={1} style={{ display: "flex" }}>
        {image ? <MDAvatar src={`${PHOTO_SERVER}${image}`} style={{ height: 40, width: 40 }} name={name} /> :
            <Icon color="inherit" fontSize="large" >account_box</Icon>
        }
        <MDBox lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
                {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
    </MDBox>
);
function Rides() {
    const navigation = useNavigate()

    const [rides, setRides] = useState([])
    const [currentItem, setCurrentItem] = useState({})
    useEffect(() => {
        fetchRides()
    }, [])


    async function fetchRides() {
        try {
            const response = await axios.get(`${ADMIN_SERVER}/rides`);
            if (response.status === 200) {
                console.log('Users retrieved successfully:', response.data.data.users);
                setRides(response.data?.data?.rides?.reverse()); // Return the list of users
            } else {
                console.error('Unexpected response code:', response.status);
            }
        } catch (error) {
            if (error.response) {
                // Server responded with a status code outside the range of 2xx
                console.error('Error response from server:', error.response.data.error || 'Unknown error');
            } else if (error.request) {
                // Request was made but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something happened while setting up the request
                console.error('Error setting up request:', error.message);
            }
        }
    }

    // Search
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredRides = rides?.filter((r) =>
        r?.user?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        r?._id?.toLowerCase().includes(searchQuery.toLowerCase()));
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SearchInput searchQuery={searchQuery} handleSearchChange={handleSearchChange} />
            <AptaTable
                columns={['rideId', "Pickup Location", "Drop Location", 'Owner', 'Price', 'Available Seats', 'Ride Status', 'View']}
                rows={
                    filteredRides?.map((ride) =>
                    ({
                        "rideId": ride?._id,
                        "Pickup Location": (
                            <MDTypography variant="caption" color="text" fontWeight="medium">
                                {ride?.pickupLocationName}
                            </MDTypography>
                        ),
                        "Drop Location": (
                            <MDTypography variant="caption" color="text" fontWeight="medium">
                                {ride?.dropLocationName}
                            </MDTypography>
                        ),
                        Owner: <MDTypography variant="caption" color="text" fontWeight="medium">
                            {ride?.user?.name}
                        </MDTypography>,
                        Price: (
                            <MDTypography variant="caption" color="text" fontWeight="medium">
                                ₹ {ride?.price}
                            </MDTypography>
                        ),
                        "Available Seats": (
                            <MDTypography variant="caption" color="text" fontWeight="medium">
                                {ride?.availableSeats}
                            </MDTypography>
                        ),
                        "Ride Status": (
                            <MDTypography variant="caption" color={ride.rideStatus === "Ride-Expired" ? "error" : "success"} fontWeight="medium">
                                {ride?.rideStatus}
                            </MDTypography>
                        ),
                        View: (
                            <Button
                                variant="contained"
                                onClick={() => navigation(`/ride-dashboard/${ride?._id}`)
                                }
                                style={{ color: "white" }}
                            >
                                <Icon>visibility</Icon>
                            </Button>
                        ),
                    }))
                }
            />

















        </DashboardLayout>
    )
}

export default Rides








const InputFormModal = ({ open, onClose, onSave, details, type }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        role: "",

    });
    useEffect(() => {
        if (details) {
            setFormData(details)
        }
    }, [details])

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle save action
    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{type === 'add' ? 'Add Staff' : 'Edit Staff'}</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Role</InputLabel>
                        <Select
                            style={{ height: 40 }}
                            label="Role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                        >
                            <MenuItem value="Supervisor">Supervisor</MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                            <MenuItem value="Employee">Employee</MenuItem>
                            {/* Add more roles as needed */}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Mobile Number"
                        name="mobile"
                        type="tel"
                        value={formData.mobile}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    {type === 'add' && <TextField
                        label="Password"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />}

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} style={{ color: "white" }} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};





export const SearchInput = ({ searchQuery, handleSearchChange }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            mb={3}
            sx={{
                // maxWidth: 600,
                width: '100%',
                backgroundColor: '#f5f5f5',
                borderRadius: '50px',
                // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                // padding: '8px 16px',
            }}
        >
            <TextField
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search for rideId, owner..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '50px',
                        padding: '8px 12px',
                    },
                    '& .MuiInputBase-input': {
                        fontSize: '16px',
                        padding: '8px',
                    },
                }}
            />
        </Box>
    );
};


