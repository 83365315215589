import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function SignIn() {
  // Predefined usernames and passwords (don't display them in the form)
  const defaultUsers = [
    // { email: "ludo@24by7.com", password: "ludo24by7" },
    { email: "admin", password: "admin" },
    // { email: "admin@domain.com", password: "adminpass" },
  ];

  const [details, setDetails] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [openDet, setOpenDet] = useState(false);
  const navigate = useNavigate(); // Using useNavigate for redirect

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateCredentials = (email, password) => {
    return defaultUsers.some(
      (user) => user.email === email && user.password === password
    );
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    // Check if the credentials are correct
    if (validateCredentials(details.email, details.password)) {
      // Store the login time (current time)
      const loginTime = Date.now();
      localStorage.setItem("loginTime", loginTime);

      // Store the expiration time (1 week from now)
      const expirationTime = loginTime + 7 * 24 * 60 * 60 * 1000;  // 7 days in milliseconds
      localStorage.setItem("sessionExpirationTime", expirationTime);

      // Redirect to the dashboard after successful login
      navigate("/dashboard");
    } else {
      // Set error message and show Snackbar
      setErrorMessage("Invalid email or password");
      setOpenDet(true);
    }
  };


  return (
    <BasicLayout
      image={bgImage}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      ></div>

      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                value={details.email}
                onChange={handleChange}
                name="email"
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                value={details.password}
                onChange={handleChange}
                name="password"
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1} style={{ display: "flex", justifyContent: "center" }}>
              <Button style={{ color: "white" }} onClick={handleSignIn} variant="contained">
                Sign In
              </Button>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      {/* Snackbar to display error message */}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openDet}
        onClose={() => setOpenDet(false)}
        message={errorMessage}
      />
    </BasicLayout>
  );
}

export default SignIn;
