import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Icon,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import AptaTable from "examples/AptaTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { ADMIN_SERVER } from "constants/constant";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill styles

function BlogManagement() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentId, setCurrentId] = useState("")
  const [formDetails, setFormDetails] = useState({
    title: "",
    content: "",
    tags: "",
    category: "",
    blogimage: "",
    metaTitle: "",
    metaDescription: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("add"); // 'add' or 'edit'

  // Fetch all blogs
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${ADMIN_SERVER}/get-all-blogs`);
      setBlogs(response.data?.reverse());
    } catch (error) {
      Swal.fire("Error", "Failed to fetch blogs.", "error");
      console.error("Error fetching blogs:", error);
    }
  };

  // Fetch all categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${ADMIN_SERVER}/get-blog-categories`);
      setCategories(response.data);
    } catch (error) {
      Swal.fire("Error", "Failed to fetch categories.", "error");
      console.error("Error fetching categories:", error);
    }
  };

  // Handle modal open
  const handleOpenModal = (type, blog = {
    title: "",
    content: "",
    tags: "",
    category: "",
    blogimage: "",
    metaTitle: "",
    metaDescription: "",
  }) => {
    setCurrentId(blog?._id)
    setModalType(type);
    setFormDetails(blog);
    setModalOpen(true);
  };

  // Handle modal close
  const handleCloseModal = () => setModalOpen(false);

  // Handle save action
  const handleSave = async (formDetails) => {
    const url =
      modalType === "add"
        ? `${ADMIN_SERVER}/create-blog`
        : `${ADMIN_SERVER}/update-blog/${currentId}`;
    const method = modalType === "add" ? "POST" : "PUT";

    try {
      // Create FormData object to handle file upload
      const formData = new FormData();
      formData.append("title", formDetails.title);
      formData.append("content", formDetails.content);
      formData.append(
        "tags",
        typeof formDetails?.tags === "string"
          ? formDetails?.tags?.split(",")
          : formDetails?.tags
      );
      formData.append("category", formDetails.category);
      formData.append("blogimage", formDetails.blogimage); // Append the uploaded file
      formData.append("metaTitle", formDetails.metaTitle);
      formData.append("metaDescription", formDetails.metaDescription);

      // Send formData using axios
      await axios({
        method,
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data", // Ensure headers support file upload
        },
      });

      Swal.fire(
        "Success",
        `Blog ${modalType === "add" ? "created" : "updated"} successfully!`,
        "success"
      );
      fetchBlogs();
      handleCloseModal();
    } catch (error) {
      handleCloseModal()
      Swal.fire("Error", "Failed to save blog.", "error");
      console.error("Error saving blog:", error);
    }
  };


  // Handle delete action with confirmation
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${ADMIN_SERVER}/delete-blog/${id}`);
          Swal.fire("Deleted!", "Your blog has been deleted.", "success");
          fetchBlogs();
        } catch (error) {
          Swal.fire("Error", "Failed to delete blog.", "error");
          console.error("Error deleting blog:", error);
        }
      }
    });
  };


  useEffect(() => {
    fetchBlogs();
    fetchCategories();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box display="flex" mb={3} justifyContent="end">
        <Button
          style={{ color: "white" }}
          startIcon={<Icon>add</Icon>}
          onClick={() =>
            handleOpenModal("add", {
              title: "",
              content: "",
              tags: "",
              category: "",
              blogimage: "",
              metaTitle: "",
              metaDescription: "",
            })
          }
          variant="contained"
        >
          Add Blog
        </Button>
      </Box>
      <AptaTable
        columns={["Title", "Category", "Tags", "Actions"]}
        rows={blogs?.map((blog) => ({
          Title: (
            <MDTypography variant="button" fontWeight="medium">
              {blog?.title}
            </MDTypography>
          ),
          Category: blog.category,
          Tags: blog?.tags?.join(","),
          // Tags: blog.tags?.map((i, idx) => `${i}${(blog?.tags?.length - 1) === idx ? "," : ""}`),
          Actions: (
            <ButtonGroup size="small">
              <Button color="info" onClick={() => handleOpenModal("edit", blog)}>
                <Icon color="info">edit</Icon>
              </Button>
              <Button color="error" onClick={() => handleDelete(blog?._id)}>
                <Icon color="error">delete</Icon>
              </Button>
            </ButtonGroup>
          ),
        }))}
      />
      <BlogModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
        details={formDetails}
        type={modalType}
        categories={categories}
      />
    </DashboardLayout>
  );
}

export default BlogManagement;

const BlogModal = ({ open, onClose, onSave, details, type, categories }) => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    tags: "",
    category: "",
    blogimage: null, // Changed to null for file upload
    metaTitle: "",
    metaDescription: "",
  });
  const [filename, setfilename] = useState(null)

  // Reset form data when modal type or details change
  useEffect(() => {
    if (type === "add") {
      setfilename(null)
      setFormData({
        title: "",
        content: "",
        tags: "",
        category: "",
        blogimage: null,
        metaTitle: "",
        metaDescription: "",
      });
    } else if (type === "edit" && details) {
      setfilename(null)
      setFormData({ ...details, blogimage: null }); // Clear blogimage for edit mode
    }
  }, [type, details]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file input change
  const handleFileChange = (e) => {

    const file = e.target.files[0];
    setfilename(file?.name)
    setFormData({ ...formData, blogimage: file });
  };

  // Handle content editor changes
  const handleContentChange = (value) => {
    setFormData({ ...formData, content: value });
  };

  // Handle save action
  const handleSave = () => {
    onSave(formData);

    if (type === "add") {
      setFormData({
        title: "",
        content: "",
        tags: "",
        category: "",
        blogimage: null,
        metaTitle: "",
        metaDescription: "",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ "& .MuiDialog-paper": { width: "80%", maxWidth: "1200px" } }}>
      <DialogTitle>{type === "add" ? "Add Blog" : "Edit Blog"}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
          <TextField
            label="Title"
            name="title"
            required
            value={formData.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Tags (comma-separated)"
            name="tags"
            required
            value={formData.tags}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              name="category"
              value={formData.category}
              onChange={handleChange}
              style={{ height: 40 }}
              required
              label="Category"
            >
              {categories?.map((cat) => (
                <MenuItem key={cat._id} value={cat.blogContent}>
                  {cat?.blogContent}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Meta Title"
            name="metaTitle"
            required
            value={formData.metaTitle}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Meta Description"
            name="metaDescription"
            value={formData.metaDescription}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            multiline
            rows={2}
          />
          <Box sx={{ marginBottom: 2 }}>
            <InputLabel sx={{ marginBottom: 1, fontWeight: 'bold' }}>Upload Image</InputLabel>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{
                padding: 2,
                textAlign: 'center',
                border: '1px dashed',
                borderColor: 'divider',
                backgroundColor: 'background.default',
                color: 'text.secondary',
                "&:hover": {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              Click to Upload
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {filename && (
              <Box sx={{ mt: 1, color: 'text.secondary', fontSize: '0.875rem' }}>
                Selected file: {filename}
              </Box>
            )}
          </Box>
          <Box sx={{ marginTop: 2, marginBottom: 5 }}>
            <InputLabel sx={{ marginBottom: 2 }}>Content</InputLabel>
            <ReactQuill
              theme="snow"
              value={formData.content}
              onChange={handleContentChange}
              style={{ height: "200px" }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={formData?.title === "" || formData?.content === "" || formData?.tags === "" || formData?.category === "" || formData?.metaDescription === "" || formData?.metaTitle === ""} style={{ color: "white" }} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
