export const sample_data = [
    {
        name: "John Michael",
        email: "john@creative-tim.com",
        role: "Manager",
        mobile: "1234567890",
        password: '12345'
    },
    {
        image: "path_to_image2",
        name: "Alexa Liras",
        email: "alexa@creative-tim.com",
        role: "Programator",
        mobile: "1234567890"

    },
    {
        image: "path_to_image3",
        name: "Laurent Perrier",
        email: "laurent@creative-tim.com",
        role: "Executive",
        mobile: "1234567890"


    },
    {
        image: "path_to_image4",
        name: "Michael Levi",
        email: "michael@creative-tim.com",
        role: "Programator",
        mobile: "1234567890"


    },
    {
        image: "path_to_image5",
        name: "Richard Gran",
        email: "richard@creative-tim.com",
        role: "Manager",
        mobile: "1234567890"


    },
    {
        image: "path_to_image6",
        name: "Miriam Eric",
        email: "miriam@creative-tim.com",
        role: "Programator",
        mobile: "1234567890"


    },
]; 