import { Box, Button, ButtonGroup, Card, CardContent, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, Switch, TextField, Typography } from '@mui/material'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import AptaTable from 'examples/AptaTable'
import MDBox from 'components/MDBox'
import { SERVER } from 'constants/constant'
import axios from 'axios'
import { ADMIN_SERVER } from 'constants/constant'
import MDAvatar from 'components/MDAvatar'
import { PHOTO_SERVER } from 'constants/constant'
import { Accordion, AccordionSummary, AccordionDetails, Avatar, Divider, Tooltip } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { SearchInput } from 'layouts/users'


const Author = ({ image, name, email }) => (

    <MDBox lineHeight={1} style={{ display: "flex" }}>
        {image ? <MDAvatar src={`${PHOTO_SERVER}${image}`} style={{ height: 40, width: 40 }} name={name} /> :
            <Icon color="inherit" fontSize="large" >account_box</Icon>
        }
        <MDBox lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
                {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
    </MDBox>
);
function Bookings() {
    const [bookings, setBookings] = useState([])
    const [currentItem, setCurrentItem] = useState({})
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchBookings()
    }, [])


    async function fetchBookings() {
        try {
            const response = await axios.get(`${ADMIN_SERVER}/bookings`);
            if (response.status === 200) {
                console.log('Users retrieved successfully:', response.data.data.users);
                setBookings(response?.data?.data?.bookings?.reverse()); // Return the list of users
            } else {
                console.error('Unexpected response code:', response.status);
            }
        } catch (error) {
            if (error.response) {
                // Server responded with a status code outside the range of 2xx
                console.error('Error response from server:', error.response.data.error || 'Unknown error');
            } else if (error.request) {
                // Request was made but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something happened while setting up the request
                console.error('Error setting up request:', error.message);
            }
        }
    }
    // Search
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredBookings = bookings?.filter((item) =>
        item?.user?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SearchInput searchQuery={searchQuery} placeholder={"Enter user name..."} handleSearchChange={handleSearchChange} />

            {/* Body */}
            <AptaTable
                columns={['user', 'request', 'date', "status"]}
                rows={
                    filteredBookings?.map((item, index) => ({
                        user: <Author image={item?.user?.profilePic} name={item?.user?.name} email={item?.user?.email} />,
                        request: <p>{item?.numberOfMembers} seats</p>,
                        date: item?.createdAt?.slice(0, 10),
                        status: <Typography sx={{ fontSize: 14, color: item?.status === "pending" ? 'red' : 'green' }}>{item?.status}</Typography>,

                    }))
                }
            />
        </DashboardLayout>
    )
}

export default Bookings

// File: ViewModal.js
const ViewModal = ({ open, handleClose, data }) => {
    if (!data) return null;

    const headingStyle = {
        fontWeight: "bold",
        color: "primary",
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Ride Details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            {/* Location Details */}
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" sx={headingStyle}>
                                    Pickup Location
                                </Typography>
                                <Typography variant="body2">
                                    Lat: {data?.pickupLocation?.coordinates?.[0] ?? "N/A"}, Lng:{" "}
                                    {data?.pickupLocation?.coordinates?.[1] ?? "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" sx={headingStyle}>
                                    Drop Location
                                </Typography>
                                <Typography variant="body2">
                                    Lat: {data?.dropLocation?.coordinates?.[0] ?? "N/A"}, Lng:{" "}
                                    {data?.dropLocation?.coordinates?.[1] ?? "N/A"}
                                </Typography>
                            </Grid>
                            {[
                                { label: "Ride Status", value: data?.rideStatus ?? "N/A" },
                                { label: "Vehicle Name", value: data?.vehicleName ?? "N/A" },
                                { label: "Description", value: data?.description ?? "N/A" },
                                { label: "Available Seats", value: data?.availableSeats ?? "N/A" },
                                { label: "Price", value: `₹ ${data?.price ?? "N/A"}` },
                                {
                                    label: "Start Time",
                                    value: data?.startTime
                                        ? new Date(data.startTime).toLocaleString()
                                        : "N/A",
                                },
                            ].map((item) => (
                                <Grid item xs={12} sm={6} key={item.label}>
                                    <Typography variant="h6" sx={headingStyle}>
                                        {item.label}
                                    </Typography>
                                    <Typography variant="body2">{item.value}</Typography>
                                </Grid>
                            ))}

                            {/* User Info */}
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={headingStyle}>
                                    User Information
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4} textAlign="center">
                                <Tooltip title={data?.user?.name ?? "N/A"}>

                                    <Avatar
                                        src={`${PHOTO_SERVER}${data?.user?.profilePic}` ?? ""}
                                        alt={data?.user?.name ?? "User"}
                                        sx={{ width: 100, height: 100, margin: "0 auto" }}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {[
                                    { label: "Name", value: data?.user?.name ?? "N/A" },
                                    { label: "Email", value: data?.user?.email ?? "N/A" },
                                    { label: "Phone", value: data?.user?.phone ?? "N/A" },
                                ].map((item) => (
                                    <Grid item key={item.label} mb={1}>
                                        <Typography variant="h6" sx={headingStyle}>
                                            {item.label}
                                        </Typography>
                                        <Typography variant="body2">{item.value}</Typography>
                                    </Grid>
                                ))}
                            </Grid>

                            {/* Requests Accordion */}
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6" sx={headingStyle}>
                                            Requests
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            {data?.requests?.length > 0 ? (
                                                data.requests.map((request) => (
                                                    <React.Fragment key={request?._id ?? "N/A"}>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={request?.user?.name ?? "N/A"}
                                                                secondary={`Description: ${request?.description ?? "N/A"
                                                                    }, Members: ${request?.numberOfMembers ?? "N/A"
                                                                    }, Status: ${request?.status ?? "N/A"}`}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <Typography variant="body2">No requests available.</Typography>
                                            )}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};










const InputFormModal = ({ open, onClose, onSave, details, type }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        role: "",

    });
    useEffect(() => {
        if (details) {
            setFormData(details)
        }
    }, [details])

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle save action
    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{type === 'add' ? 'Add Staff' : 'Edit Staff'}</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Role</InputLabel>
                        <Select
                            style={{ height: 40 }}
                            label="Role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                        >
                            <MenuItem value="Supervisor">Supervisor</MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                            <MenuItem value="Employee">Employee</MenuItem>
                            {/* Add more roles as needed */}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Mobile Number"
                        name="mobile"
                        type="tel"
                        value={formData.mobile}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    {type === 'add' && <TextField
                        label="Password"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />}

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} style={{ color: "white" }} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};




