import { Box, Button, ButtonGroup, Card, CardContent, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, Switch, TextField, Toolbar, Typography, Tooltip } from '@mui/material'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import AptaTable from 'examples/AptaTable'
import MDBox from 'components/MDBox'
import axios from 'axios'
import { ADMIN_SERVER } from 'constants/constant'
import MDAvatar from 'components/MDAvatar'
import { PHOTO_SERVER } from 'constants/constant'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { SearchInput } from 'layouts/users'





export const Author = ({ image, name, email }) => (

    <MDBox lineHeight={1} style={{ display: "flex" }}>
        {image ? <MDAvatar src={`${PHOTO_SERVER}${image}`} style={{ height: 40, width: 40 }} name={name} /> :
            <Icon color="inherit" fontSize="large" >account_box</Icon>
        }
        <MDBox lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
                {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
    </MDBox>
);
function Reviews() {
    const navigation = useNavigate()
    const [reviews, setreviews] = useState([])
    const [currentItem, setCurrentItem] = useState({})
    const [open, setOpen] = useState(false);
    useEffect(() => {
        fetchReviews()
    }, [])


    async function fetchReviews() {
        try {
            const response = await axios.get(`${ADMIN_SERVER}/reviews`);
            if (response.status === 200) {
                setreviews(response.data.data?.reviews?.reverse()); // Return the list of reviews
            } else {
                console.error('Unexpected response code:', response.status);
            }
        } catch (error) {
            if (error.response) {
                // Server responded with a status code outside the range of 2xx
                console.error('Error response from server:', error.response.data.error || 'Unknown error');
            } else if (error.request) {
                // Request was made but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something happened while setting up the request
                console.error('Error setting up request:', error.message);
            }
        }
    }
    // Search
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredArray = reviews?.filter((review) =>
        review?.reviewee?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        review?.reviewer?.name.toLowerCase().includes(searchQuery.toLowerCase()));



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SearchInput searchQuery={searchQuery} handleSearchChange={handleSearchChange} placeholder="Enter reviewer, reviewee..." />

            {/* Body */
                <AptaTable
                    columns={['Reviewer', 'Reviewee', 'Rating', 'Comment', 'Created At']}
                    rows={
                        filteredArray?.map((review) => ({
                            Reviewer: (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {review?.reviewer?.name || "N/A"}
                                </MDTypography>
                            ),
                            Reviewee: (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {review?.reviewee?.name || "N/A"}
                                </MDTypography>
                            ),
                            Rating: (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {review?.rating || "N/A"}
                                </MDTypography>
                            ),
                            Comment: (
                                <Tooltip title={review?.comment || "N/A"} arrow>
                                    <MDTypography variant="caption" color="text" fontWeight="medium">
                                        {review?.comment?.length > 20
                                            ? `${review.comment.substring(0, 20)}...`
                                            : review.comment || "N/A"}
                                    </MDTypography>
                                </Tooltip>
                            ),
                            "Created At": (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {moment(review?.createdAt).format('MMMM Do YYYY, h:mm A') || "N/A"}
                                </MDTypography>
                            ),
                        }))
                    }
                />

            };
        </DashboardLayout>
    )
}

export default Reviews

