/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Staffs from "layouts/staffs";
import Users from "layouts/users";
import Leads from "layouts/leads";
import Sales from "layouts/sales";
import Wallet from "layouts/wallet";
import Website from "layouts/website";
import Charges from "layouts/charges";
import Feedbacks from "layouts/feedbacks";
import DashComp from "layouts/dashcomp";
import Roles from "layouts/role";
import Locations from "layouts/locations";
import Cities from "layouts/cities";
import Rides from "layouts/rides";
import Bookings from "layouts/bookings";
import Plans from "layouts/plans";
import ProtectedRoute from "components/ProtectedRoute";
import UserDashboard from "layouts/user-dashboard";
import RideDashboard from "layouts/ride-dashboard";
import Reviews from "layouts/reviews";
import Blogs from "layouts/blogs";
import BlogCategory from "layouts/blog-category";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  // {
  //   type: "dropdown",
  //   name: "Access Control",
  //   icon: <Icon>lock</Icon>,  // replace with your icon component
  //   key: "management",
  //   items: [
  //     {
  //       type: "collapse",
  //       name: "Roles",
  //       key: "roles",
  //       icon: <Icon fontSize="small">attribution</Icon>,
  //       route: "/roles",
  //       component: <ProtectedRoute><Roles /></ProtectedRoute>,
  //     },
  //     {
  //       type: "collapse",
  //       name: "Staffs",
  //       key: "staffs",
  //       icon: <Icon fontSize="small">people</Icon>,
  //       route: "/staffs",
  //       component: <ProtectedRoute><Staffs /></ProtectedRoute>,
  //     },
  //   ]
  // },
  {
    type: "collapse",
    name: "Cities",
    key: "cities",
    icon: <Icon fontSize="small">flag</Icon>,
    route: "/cities",
    component: (
      <ProtectedRoute>
        <Cities />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Locations",
    key: "locations",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/locations",
    component: (
      <ProtectedRoute>
        <Locations />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/users",
    component: (
      <ProtectedRoute>
        <Users />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Rides",
    key: "rides",
    icon: <Icon fontSize="small">local_taxi</Icon>,
    route: "/rides",
    component: (
      <ProtectedRoute>
        <Rides />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Bookings",
    key: "bookings",
    icon: <Icon fontSize="small">local_taxi</Icon>,
    route: "/bookings",
    component: (
      <ProtectedRoute>
        <Bookings />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Reviews",
    key: "reviews",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/reviews",
    component: (
      <ProtectedRoute>
        <Reviews />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/plans",
    component: (
      <ProtectedRoute>
        <Plans />
      </ProtectedRoute>
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Leads",
  //   key: "lead_lists",
  //   icon: <Icon fontSize="small">reorder</Icon>,
  //   route: "/leads",
  //   component: <Leads />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sales",
  //   key: "sales",
  //   icon: <Icon fontSize="small">sell</Icon>,
  //   route: "/sales",
  //   component: <Sales />,
  // },
  {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/wallet",
    component: (
      <ProtectedRoute>
        <Wallet />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Blog Category",
    key: "blogs-category",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/blogs-category",
    component: (
      <ProtectedRoute>
        <BlogCategory />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/blogs",
    component: (
      <ProtectedRoute>
        <Blogs />
      </ProtectedRoute>
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Categories",
  //   key: "categories",
  //   icon: <Icon fontSize="small">category</Icon>,
  //   route: "/categories",
  //   component: <Categories />,
  // },

  {
    type: "dropdown",
    name: "Settings",
    icon: <Icon>settings</Icon>, // replace with your icon component
    key: "settings",
    items: [
      {
        type: "collapse",
        name: "Website",
        key: "website",
        icon: <Icon fontSize="small">computer</Icon>,
        route: "/website",
        component: (
          <ProtectedRoute>
            <Website />
          </ProtectedRoute>
        ),
      },
      {
        type: "collapse",
        name: "Charges/Fares",
        key: "charges",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "/charges",
        component: (
          <ProtectedRoute>
            <Charges />
          </ProtectedRoute>
        ),
      },
      {
        type: "collapse",
        name: "Feedbacks",
        key: "feedbacks",
        icon: <Icon fontSize="small">forum</Icon>,
        route: "/feedbacks",
        component: (
          <ProtectedRoute>
            <Feedbacks />
          </ProtectedRoute>
        ),
      },
      // {
      //   type: "collapse",
      //   name: "FAQs",
      //   key: "FAQs",
      //   icon: <Icon fontSize="small">category</Icon>,
      //   route: "/faqs",
      //   component: <ProtectedRoute><Cities /></ProtectedRoute>,
      // }
      // {
      //   type: "collapse",
      //   name: "Profile",
      //   key: "profile",
      //   icon: <Icon fontSize="small">person</Icon>,
      //   route: "/profile",
      //   component: <Profile />,
      // },
      // {
      //   type: "collapse",
      //   name: "Notifications",
      //   key: "notifications",
      //   icon: <Icon fontSize="small">notifications</Icon>,
      //   route: "/notifications",
      //   component: <Notifications />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Roles",
    key: "roles",
    icon: <Icon fontSize="small">attribution</Icon>,
    route: "/roles",
    component: (
      <ProtectedRoute>
        <Roles />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Staffs",
    key: "staffs",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/staffs",
    component: (
      <ProtectedRoute>
        <Staffs />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Website",
    key: "website",
    icon: <Icon fontSize="small">computer</Icon>,
    route: "/website",
    component: (
      <ProtectedRoute>
        <Website />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Charges/Fares",
    key: "charges",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/charges",
    component: (
      <ProtectedRoute>
        <Charges />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Feedbacks",
    key: "feedbacks",
    icon: <Icon fontSize="small">forum</Icon>,
    route: "/feedbacks",
    component: (
      <ProtectedRoute>
        <Feedbacks />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: (
      <ProtectedRoute>
        <Notifications />
      </ProtectedRoute>
    ),
  },
  // ---------------------------------
  // {
  //   type: "collapse",
  //   name: "Dashboard_Comp",
  //   key: "dash_comp",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dash_comp",
  //   component: <DashComp />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table</Icon>,
  //   route: "/table",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },

  {
    type: "route",
    hide: true,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "route",
    hide: true,
    route: "/user-dashboard/:userId",
    component: <UserDashboard />,
  },
  {
    type: "route",
    hide: true,
    route: "/ride-dashboard/:rideId",
    component: <RideDashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  // {
  //   type: "collapse",
  //   hide: true,
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
