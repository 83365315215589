import { Box, Button, ButtonGroup, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { sample_data } from './data/constant'
import AptaTable from 'examples/AptaTable'
import MDBox from 'components/MDBox'


const Author = ({ image, name, email }) => (
    <MDBox lineHeight={1}>
        {/* <MDAvatar src={image} name={name} size="sm" /> */}
        <MDBox lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
                {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
    </MDBox>
);
function Staffs() {
    const [details, setDetails] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        role: "",

    })
    const [open, setOpen] = useState(false);
    const [roleType, setRoleType] = useState('add')
    const handleOpen = (type, item) => {
        setRoleType(type)
        setDetails(item)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const handleSave = (data) => {
        console.log("Form Data:", data);
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />

            {/* Body */}
            <Box display={'flex'} mb={3} justifyContent={'end'}>
                <Button style={{ color: 'white' }} startIcon={<Icon>add</Icon>}
                    onClick={() => handleOpen('add', {
                        name: "",
                        email: "",
                        mobile: "",
                        password: "",
                        role: "",

                    })}
                    variant='contained'  >Add Staff</Button>
            </Box>
            <AptaTable
                columns={['name', 'mobile', 'function', 'actions']}
                rows={
                    sample_data?.map((item, index) => ({
                        name: <Author image={item?.image} name={item?.name} email={item?.email} />,
                        mobile:
                            // <Job title={item?.title} description={item?.description} />
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {item?.mobile}
                            </MDTypography>
                        ,
                        function:
                            // <Job title={item?.title} description={item?.description} />
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {item?.role}
                            </MDTypography>
                        ,
                        actions:
                            <ButtonGroup size='small'>
                                <Button color='info' onClick={() => handleOpen('edit', item)} >
                                    <Icon color='info' >edit</Icon>
                                </Button>
                                <Button color="error">
                                    <Icon color='error'>delete</Icon>
                                </Button>
                            </ButtonGroup>
                    }))
                }


            />
            <InputFormModal open={open} onClose={handleClose} onSave={handleSave} details={details} type={roleType} />

        </DashboardLayout>
    )
}

export default Staffs


const InputFormModal = ({ open, onClose, onSave, details, type }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        role: "",

    });
    useEffect(() => {
        if (details) {
            setFormData(details)
        }
    }, [details])

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle save action
    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{type === 'add' ? 'Add Staff' : 'Edit Staff'}</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Role</InputLabel>
                        <Select
                            style={{ height: 40 }}
                            label="Role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                        >
                            <MenuItem value="Supervisor">Supervisor</MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                            <MenuItem value="Employee">Employee</MenuItem>
                            {/* Add more roles as needed */}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Mobile Number"
                        name="mobile"
                        type="tel"
                        value={formData.mobile}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    {type === 'add' && <TextField
                        label="Password"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />}

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} style={{ color: "white" }} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};




