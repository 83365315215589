import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const loginTime = localStorage.getItem('loginTime');
  const sessionExpirationTime = localStorage.getItem('sessionExpirationTime');

  // Check if the user is authenticated and if the session has expired
  if (!loginTime || !sessionExpirationTime) {
    return <Navigate to="/authentication/sign-in" />;
  }

  // Check if the session has expired
  if (Date.now() > sessionExpirationTime) {
    // If expired, remove login info and redirect to sign-in
    localStorage.removeItem('loginTime');
    localStorage.removeItem('sessionExpirationTime');
    return <Navigate to="/authentication/sign-in" />;
  }

  // If authenticated and session is not expired, render the protected component
  return children;
};

export default ProtectedRoute;
