// Imports
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Icon,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AptaTable from "examples/AptaTable";
import { ADMIN_SERVER } from "constants/constant";
import Swal from "sweetalert2";
import { SearchInput } from "layouts/users";

// Axios instance
const api = axios.create({
    baseURL: ADMIN_SERVER,
    headers: {
        "Content-Type": "application/json",
    },
});

// API functions
const createCity = async (cityData) => {
    try {
        await api.post("/city/create", cityData);
        Swal.fire({
            title: "Success!",
            text: "A new city has been added in the list",
            icon: "success"
        });
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Try Again!',
            text: 'Failed to add the city',
            confirmButtonText: 'OK',
        });
        // window.alert(error.response?.data?.message || "Failed to create city.");
    }
};

const listCities = async () => {
    try {
        const response = await api.get("/city/list");
        return response.data?.data?.cities || [];
    } catch (error) {
        // window.alert(error.response?.data?.message || "Failed to retrieve cities.");
        return [];
    }
};

const updateCity = async (cityId, cityData) => {
    try {
        await api.put(`/city/update`, { cityId, ...cityData });
        Swal.fire({
            title: "Success!",
            text: "Successfully updated city details.",
            icon: "success"
        });
        // window.alert("City updated successfully!");
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Try Again!',
            text: 'Failed to update city',
            confirmButtonText: 'OK',
        });
        // window.alert(error.response?.data?.message || "Failed to update city.");
    }
};
const deleteCity = async (cityId) => {
    try {
        await api.delete(`/city/delete`, { data: { cityId } });
        Swal.fire({
            title: "Success!",
            text: "The city has been deleted",
            icon: "success"
        });
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Failed to delete city',
            text: 'An unexpected error occurred. Please try again !',
            confirmButtonText: 'OK',
        });
        // window.alert(error.response?.data?.message || "Failed to delete city.");
    }
};

// React Component
function Cities() {
    const [cityList, setCityList] = useState([]);
    const [details, setDetails] = useState({
        name: "",
        locationPoint: { coordinates: [0, 0] },
    });
    const [open, setOpen] = useState(false);
    const [roleType, setRoleType] = useState("add");

    const handleOpen = (type, item = { name: "", locationPoint: { coordinates: [0, 0] } }) => {
        setRoleType(type);
        setDetails(item);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        (async () => {
            const cities = await listCities();
            setCityList(cities);
        })();
    }, []);

    const handleSave = async (cityData) => {
        if (roleType === "add") await createCity(cityData);
        else if (roleType === "edit") await updateCity(details._id, cityData);
        const cities = await listCities();
        setCityList(cities);
        setOpen(false);
    };

    const handleDelete = async (cityId) => {
        await deleteCity(cityId);
        const cities = await listCities();
        setCityList(cities);
    };

    // Search
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredCity = cityList?.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />

            {/* Add City Button */}
            <Box display="flex" mb={3} justifyContent="end">
                <Button
                    style={{ color: "white" }}
                    startIcon={<Icon>add</Icon>}
                    onClick={() => handleOpen("add")}
                    variant="contained"
                >
                    Add City
                </Button>
            </Box>
            <SearchInput searchQuery={searchQuery} placeholder={"Enter city name..."} handleSearchChange={handleSearchChange} />

            {/* Cities Table */}
            <AptaTable
                columns={["name", "latitude", "longitude", "actions"]}
                rows={filteredCity.map((item) => ({
                    name: (
                        <MDTypography display="block" variant="button" fontWeight="medium">
                            {item?.name || "N/A"}
                        </MDTypography>
                    ),
                    latitude: (
                        item?.locationPoint?.coordinates?.[0] || "N/A"
                    ),
                    longitude: (
                        item?.locationPoint?.coordinates?.[1] || "N/A"
                    ),
                    actions: (
                        <ButtonGroup size="small">
                            <Button color="info" onClick={() => handleOpen("edit", item)}>
                                <Icon color="info">edit</Icon>
                            </Button>
                            <Button color="error" onClick={() => {
                                Swal.fire({
                                    title: "Are you sure you want to delete this city?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        handleDelete(item?._id)
                                    }
                                });
                            }}>
                                <Icon color="error">delete</Icon>
                            </Button>
                        </ButtonGroup>
                    ),
                }))}
            />

            {/* Input Modal */}
            <AddCityDialog
                open={open}
                onClose={handleClose}
                onSave={handleSave}
                details={details}
                roleType={roleType}
            />
        </DashboardLayout>
    );
}

export default Cities;

// Add City Dialog Component
const AddCityDialog = ({ open, onClose, onSave, details, roleType }) => {
    const [formData, setFormData] = useState({
        name: "",
        locationPoint: { coordinates: [0, 0] },
        nearbyPoints: [],
    });

    useEffect(() => {
        setFormData({
            name: details.name || "",
            locationPoint: {
                coordinates: details.locationPoint?.coordinates || [0, 0],
            },
            nearbyPoints: details.nearbyPoints || [],
        });
    }, [details]);

    const handleChange = (e, key = null, index = null, subKey = null) => {
        const { name, value } = e.target;
        if (key === "locationPoint" && subKey === "latitude") {
            setFormData((prev) => ({
                ...prev,
                locationPoint: {
                    coordinates: [prev.locationPoint.coordinates[0], parseFloat(value)],
                },
            }));
        } else if (key === "locationPoint" && subKey === "longitude") {
            setFormData((prev) => ({
                ...prev,
                locationPoint: {
                    coordinates: [parseFloat(value), prev.locationPoint.coordinates[1]],
                },
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleSave = () => {
        const cityData = {
            name: formData.name,
            coordinates: [
                formData.locationPoint.coordinates[1], // latitude
                formData.locationPoint.coordinates[0], // longitude
            ],
        };
        onSave(cityData);
    };


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{roleType === "add" ? "Add City" : "Edit City"}</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Latitude"
                        name="latitude"
                        value={formData.locationPoint.coordinates[1]}
                        onChange={(e) => handleChange(e, "locationPoint", null, "latitude")}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Longitude"
                        name="longitude"
                        value={formData.locationPoint.coordinates[0]}
                        onChange={(e) => handleChange(e, "locationPoint", null, "longitude")}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    style={{ color: "white" }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
