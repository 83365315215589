import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Container,
    Box,
    ButtonGroup,
    Icon,
    DialogContent,
    TextField,
    DialogActions,
    Dialog,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AptaTable from "examples/AptaTable";
import MDTypography from "components/MDTypography";
import { ADMIN_SERVER } from "constants/constant";
import axios from "axios";

function Wallet() {
    const [walletBalance, setWalletBalance] = useState(1000); // example balance
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([])
    const [open, setOpen] = useState(false);
    const [requestId, setRequestId] = useState("")
    const [pendingWithdrawals, setPendingWithdrawals] = useState([
        { id: 3, amount: 200, date: "2024-03-20", status: "Pending" },
    ]);


    const getAllWithdrawalsRequests = async () => {
        try {
            // Send a GET request to fetch all withdrawals
            const response = await axios.get(`${ADMIN_SERVER}/withdrawals/all-withdrawals-request`);

            if (response.status === 200) {
                const { pendingRequests } = response.data;

                // Handle the pendingRequests array
                // setPendingWithdrawals(pendingRequests)
                setWithdrawalHistory(pendingRequests)
                setPendingRequests(pendingRequests?.filter(i => i?.status?.toLowerCase() === 'pending'))
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            // Handle error scenarios
            console.error("Error fetching withdrawals requests:", error?.response?.data || error);
        }
    };
    const processWithdrawal = async () => {
        try {
            // Request body
            const requestBody = {
                transactionId: "",
                withdrawalRequestId: requestId, // The withdrawal request ID
                transactionStatus: "success"
            };
            const response = await axios.post(`${ADMIN_SERVER}/withdrawals/process-request-withdrawal`, requestBody);

            // Handle response
            if (response.status === 200) {
                const { message } = response.data;
                console.log("Success:", message);
                getAllWithdrawalsRequests()
            }
        } catch (error) {
            // Handle error responses
            if (error.response) {
                const { status } = error.response;
                if (status === 400) {
                    console.error("Error: Invalid or already processed request.");

                } else if (status === 500) {
                    console.error("Error: Internal server error.");

                }
            } else {
                console.error("Error: Unable to connect to the server.", error);

            }
        }
    };

    useEffect(() => {
        getAllWithdrawalsRequests();
    }, [])

    const handleSave = async (data) => {
        setOpen(false)
        try {
            // Request body
            const requestBody = {
                transactionId: data?.transactionId,
                withdrawalRequestId: requestId, // The withdrawal request ID
                transactionStatus: "success"
            };

            const response = await axios.post(`${ADMIN_SERVER}/withdrawals/process-request-withdrawal`, requestBody);

            // Handle response
            if (response.status === 200) {
                const { message } = response.data;
                console.log("Success:", message);
                alert("Success")
                getAllWithdrawalsRequests()
            }
        } catch (error) {
            // Handle error responses
            if (error.response) {
                const { status } = error.response;
                if (status === 400) {
                    console.error("Error: Invalid or already processed request.");

                } else if (status === 500) {
                    console.error("Error: Internal server error.");

                }
            } else {
                console.error("Error: Unable to connect to the server.", error);

            }
        }
    };
    const handleClose = () => setOpen(false);


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>

                <Container sx={{
                    display: 'flex',
                    marginTop: 5, justifyContent: 'center', flexDirection: 'column',
                    rowGap: 5
                }}>
                    {/* Withdrawal History Table */}
                    <Paper style={{ padding: 15, }}>
                        <Typography variant="h5">Withdrawal History</Typography>

                        <AptaTable
                            columns={['name', 'date', 'amount', 'status', 'actions']}
                            rows={
                                withdrawalHistory?.map((item, index) => ({
                                    name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                        {item?.user?.name}
                                        {/* {item?._id} */}
                                    </MDTypography>,
                                    date:
                                        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                            {item?.createdAt?.slice(0, 10)}
                                        </MDTypography>,
                                    amount:
                                        <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium">
                                            ₹ {item?.amount}
                                        </MDTypography>
                                    ,
                                    status:
                                        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                                            {item?.status}
                                        </MDTypography>
                                    ,
                                    actions:
                                        item?.status?.toLowerCase() !== 'pending' ? <ButtonGroup size='small'>
                                            <Button color='info'  >
                                                <Icon color='info' >edit</Icon>
                                            </Button>
                                        </ButtonGroup> : <ButtonGroup size='small'>
                                            {/* <Button color='error'  >
                                                <Icon color='error' >close</Icon>
                                            </Button> */}
                                            <Button color='info' onClick={() => {
                                                // processWithdrawal(item?._id)
                                                setRequestId(item?._id)
                                                setOpen(true)
                                            }}  >
                                                <Icon color='info' >done</Icon>
                                            </Button>
                                        </ButtonGroup>
                                }))
                            }


                        />
                    </Paper>
                    {/* Pending Withdrawals Table */}
                    <Paper style={{ padding: 15 }}>
                        <Typography variant="h5">Pending Withdrawals</Typography>

                        <AptaTable
                            columns={['name', 'date', 'amount', 'status', 'actions']}
                            rows={
                                pendingRequests?.map((item, index) => ({
                                    name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                        {item?.user?.name}
                                        {/* {item?._id} */}
                                    </MDTypography>,
                                    date:
                                        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                            {item?.createdAt?.slice(0, 10)}
                                        </MDTypography>,
                                    amount:
                                        <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium">
                                            ₹ {item?.amount}
                                        </MDTypography>
                                    ,
                                    status:
                                        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                                            {item?.status}
                                        </MDTypography>
                                    ,
                                    actions:
                                        item?.status?.toLowerCase() !== 'pending' ? <ButtonGroup size='small'>
                                            <Button color='info'  >
                                                <Icon color='info' >edit</Icon>
                                            </Button>
                                        </ButtonGroup> : <ButtonGroup size='small'>
                                            {/* <Button color='error'  >
                                                <Icon color='error' >close</Icon>
                                            </Button> */}
                                            <Button color='info' onClick={() => {
                                                // processWithdrawal(item?._id)
                                                setRequestId(item?._id)
                                                setOpen(true)
                                            }}  >
                                                <Icon color='info' >done</Icon>
                                            </Button>
                                        </ButtonGroup>
                                }))
                            }


                        />
                    </Paper>
                </Container>

            </Grid>
            {/* Input Modal */}
            <AddCityDialog
                open={open}
                onClose={handleClose}
                onSave={handleSave}
            />
        </DashboardLayout>
    );
}
export default Wallet;

// Add City Dialog Component
const AddCityDialog = ({ open, onClose, onSave, }) => {
    const [formData, setFormData] = useState({
        transactionId: "",
    });

    const handleChange = (e, key = null, index = null, subKey = null) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = () => {
        const data = {
            transactionId: formData.transactionId,
        };
        onSave(data);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            {/* <DialogTitle></DialogTitle> */}
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                    <TextField
                        label="TransactionId"
                        name="transactionId"
                        value={formData.transactionId}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    style={{ color: "white" }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};