import { Box, Button, ButtonGroup, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Icon, Modal, TextField, Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import MDBadge from 'components/MDBadge'
import AptaTable from 'examples/AptaTable'
import { roles_array } from './data/constant'

const sample_roles = {
    roles: {
        list: true,
        create: true,
        store: true,
        edit: true,
        update: true,
        delete: true,
    },
    admins: {
        list: true,
        create: true,
        store: true,
        edit: true,
        update: true,
        delete: true,
    },
    users: {
        list: false,
        edit: false,
        view: false,
        delete: false,
    },
    approvedUsers: {
        list: true,
    },
    PendingUsers: {
        list: true
    }
}


function Roles() {
    const [details, setDetails] = useState({
        role: "",
        permissions: sample_roles
    })
    const [open, setOpen] = useState(false);
    const [roleType, setRoleType] = useState('add')
    const handleClose = () => setOpen(false);

    const handleOpen = (type, item) => {
        setRoleType(type)
        setDetails(item)
        setOpen(true)
    };
    const handleSave = (userName, permissions) => {
        console.log("Form Data:", userName, permissions);
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />

            {/* Body */}
            <Box display={'flex'} mb={3} justifyContent={'end'}>
                <Button style={{ color: 'white' }} startIcon={<Icon>add</Icon>}
                    onClick={() => handleOpen('add', { role: "", permissions: sample_roles })}
                    variant='contained'  >Add Role</Button>
            </Box>
            <AptaTable
                columns={['all', 'role', 'actions']?.map(item => ({ Header: item, accessor: item, width: item === "all" ? '10%' : "25%" }))}
                columnsModify={true}
                rows={
                    roles_array?.map((item, index) => ({
                        all: <Checkbox
                            //   checked={checked}
                            //   onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />,
                        role:
                            <MDTypography display="block" color="info" variant="button" fontWeight="medium">
                                {item?.role}
                            </MDTypography>, actions:
                            <ButtonGroup size='small'>
                                <Button color='info' onClick={() => handleOpen('edit', item)} >
                                    <Icon color='info' >edit</Icon>
                                </Button>
                                <Button color="error">
                                    <Icon color='error'>delete</Icon>
                                </Button>
                            </ButtonGroup>
                    }))
                }


            />
            {/* <InputFormModal open={open} onClose={handleClose} onSave={handleSave} /> */}
            <RolePermissionModal open={open} onClose={handleClose} details={details} onSave={handleSave} roleType={roleType} />
        </DashboardLayout>
    )
}

export default Roles


const RolePermissionModal = ({ open, onClose, onSave, details, roleType }) => {
    // State to handle all permissions
    const [allPermissions, setAllPermissions] = useState(false);


    // State to handle individual permissions
    const [role, setRole] = useState("")
    const [permissions, setPermissions] = useState(sample_roles);
    useEffect(() => {
        setRole(details?.role)
        setPermissions(details?.permissions)
    }, [details])
    // Handle change for "All Permissions"
    const handleAllPermissionsChange = () => {
        const newPermissions = {};
        Object.keys(permissions).forEach((menu) => {
            newPermissions[menu] = {};
            Object.keys(permissions[menu]).forEach((perm) => {
                newPermissions[menu][perm] = !allPermissions;
            });
        });
        setPermissions(newPermissions);
        setAllPermissions(!allPermissions);
    };

    // Handle individual permission change
    const handlePermissionChange = (menu, permission) => {
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [menu]: {
                ...prevPermissions[menu],
                [permission]: !prevPermissions[menu][permission],
            },
        }));
    };
    const handleSave = () => {
        onSave(role, permissions);
        onClose();
    };


    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{roleType === 'add' ? 'Add Role' : "Role Details"}</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ mb: 1 }}>
                        {/* <Typography variant="body2" sx={{ p: 1, bgcolor: "#f0f0f0", borderRadius: 1 }}>
                            {userName}

                        </Typography> */}
                        <TextField
                            label="Role"
                            name="name"
                            value={role}
                            onChange={(e) => setRole(e.target?.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                    <FormControlLabel
                        control={<Checkbox checked={allPermissions} onChange={handleAllPermissionsChange} />}
                        label="All Permissions"
                    />
                </Box>

                {/* Permission Table */}
                <Box sx={{ mt: 2, border: "1px solid #e0e0e0", borderRadius: 1 }}>
                    {/* <Grid container sx={{ bgcolor: "#f7f7f7", p: 1 }}>
                        <Grid item xs={3}>
                            <Typography variant="body2" fontWeight="bold">Menu</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" fontWeight="bold">Permission</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ p: 1, borderBottom: "1px solid #e0e0e0" }}>
                        <Grid item xs={3}>
                            <Typography variant="body2">Roles</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {["list", "create", "store", "edit", "update", "delete"]?.map((perm) => (
                                <FormControlLabel
                                    key={`roles-${perm}`}
                                    control={
                                        <Checkbox
                                            checked={permissions.roles[perm]}
                                            onChange={() => handlePermissionChange("roles", perm)}
                                        />
                                    }
                                    label={perm.charAt(0).toUpperCase() + perm.slice(1)}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container sx={{ p: 1, borderBottom: "1px solid #e0e0e0" }}>
                        <Grid item xs={3}>
                            <Typography variant="body2">Admins</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {["list", "create", "store", "edit", "update", "delete"].map((perm) => (
                                <FormControlLabel
                                    key={`admins-${perm}`}
                                    control={
                                        <Checkbox
                                            checked={permissions.admins[perm]}
                                            onChange={() => handlePermissionChange("admins", perm)}
                                        />
                                    }
                                    label={perm.charAt(0).toUpperCase() + perm.slice(1)}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container sx={{ p: 1, borderBottom: "1px solid #e0e0e0" }}>
                        <Grid item xs={3}>
                            <Typography variant="body2">Users / Customer</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {["list", "edit", "view", "delete"].map((perm) => (
                                <FormControlLabel
                                    key={`users-${perm}`}
                                    control={
                                        <Checkbox
                                            checked={permissions.users[perm]}
                                            onChange={() => handlePermissionChange("users", perm)}
                                        />
                                    }
                                    label={perm.charAt(0).toUpperCase() + perm.slice(1)}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container sx={{ p: 1 }}>
                        <Grid item xs={3}>
                            <Typography variant="body2">Approved Users</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissions.approvedUsers.list}
                                        onChange={() => handlePermissionChange("approvedUsers", "list")}
                                    />
                                }
                                label="List"
                            />
                        </Grid>
                    </Grid> */}
                    {Object?.entries(permissions)?.map(([category, perms]) => (
                        <Grid container key={category} sx={{ p: 1, borderBottom: "1px solid #e0e0e0" }}>
                            <Grid item xs={3}>
                                <Typography variant="body2">
                                    {category.charAt(0).toUpperCase() + category.slice(1)}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <FormGroup row>
                                    {Object.keys(perms).map((perm) => (
                                        <FormControlLabel
                                            key={`${category}-${perm}`}
                                            control={
                                                <Checkbox
                                                    checked={permissions[category][perm]}
                                                    onChange={() => handlePermissionChange(category, perm)}
                                                />
                                            }
                                            label={perm.charAt(0).toUpperCase() + perm.slice(1)}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained" style={{ color: "white" }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};