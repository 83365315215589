export const COMPANY_NAME = '1T4'
export const SERVER = `https://api.1t4.in/api/1t4`
export const ADMIN_SERVER = `https://api.1t4.in/api/admin/1t4`
export const PHOTO_SERVER = "https://api.1t4.in/uploads/"

export const COLORS = {
    primary: '#EB8C2D',
    secondary: '#DB1E3E',
    third: '#203251',
    forth: "#133822"
}